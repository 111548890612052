import React from 'react';
import {
  Text,
  TextTypography,
  ButtonPriority,
  TextButtonPriority,
  TPAComponentsConfig,
} from 'wix-ui-tpa';

import { FeedTopics } from '../../Discussion/FeedTopics/FeedTopics';
import { classes, st } from './NewPostModal.st.css';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import {
  InjectedExperimentsProps,
  InjectedErrorMonitorProps,
  withExperiments,
  withErrorMonitor,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';
import {
  IEditorEvents,
  IEditorEventsContext,
} from '../../../contexts/PostContentEditor/IEditorEvents';
import { Modal, ModalSkin } from '../../../../../common/components/Modal';
import { Loader } from '../../../../../common/components/Loader/Loader';
import { Button } from '../../../../../common/components/Button/Button';
import { Spinner } from '../../../../../common/components/Spinner';
import { RichContentEditor } from '../../RichContent/lazy';
import { compose } from '../../../../../common/utils/compose';
import { withTPAConfig } from '../../../../../common/components/withTPAConfig';
import { EFilterKeys } from '../../../types/EFilterKeys';
import { NEW_POST_MODAL } from '../../dataHooks';
import { Theme, withTheme } from '../../../../../common/context/theme';
import { TextButton } from '../../../../../common/components/TextButton/TextButton';
import { PRESETS } from '../../RichContent/types';
import { Author } from './Author';

interface NewPostModalProps {
  isOpen: boolean;
  isPostPublishing: boolean;
  topicId?: string;
  feedItemId?: string;
  initialContentState?: RawDraftContentState<any>;

  onSubmit(post: RawDraftContentState<any>, topicId?: string): void;
  onEditorNodeChanged?(editorRef: any): void;
  onSaveDraft?(post: RawDraftContentState<any>): void;
  discardPost(): void;
}

type Props = NewPostModalProps &
  WithTranslation &
  TPAComponentsConfig &
  WithGroupProps &
  Theme &
  InjectedErrorMonitorProps &
  InjectedExperimentsProps;

interface State {
  post: RawDraftContentState<any>;
  isBusy: boolean;
  topicId: string;
}

class NewPostModalComponent extends React.Component<Props, State> {
  static displayName = 'NewPostModalComponent';
  static defaultProps = {
    initialContentState: null,
    onSaveDraft() {},
    currentMember: {
      name: {},
    },
  };

  readonly state: State = {
    post: null as any,
    isBusy: false,
    topicId: this.props.topicId!,
  };

  componentDidUpdate(props: Props) {
    if (this.props.topicId !== props.topicId) {
      this.setState({
        topicId: this.props.topicId!,
      });
    }
  }

  private editorEvents!: IEditorEventsContext;
  private editorEventTypes!: IEditorEvents;
  protected $editor: any;

  setEditorRef = (editorNode: any) => {
    this.$editor = editorNode;
    this.props.onEditorNodeChanged?.(editorNode);
  };

  handleClose = () => {
    this.props.onSaveDraft?.(this.state.post);
    this.props.discardPost();
  };

  setPost = (post?: any) => {
    this.setState({ post });
  };

  private handleCancel = () => {
    this.setState({ post: null as any }, () => {
      this.handleClose();
    });
  };

  handleSubmit = async () => {
    try {
      await this.editorEvents.dispatch(this.editorEventTypes.PUBLISH);
    } catch (e) {
      console.log('PostEditorComponent Error in EditorEvents');
      this.props.errorMonitor.captureException(e);
    } finally {
      const forPublish = true;
      const shouldRemoveErrorBlocks = false;
      const content = await this.$editor.getContent(
        null,
        forPublish,
        shouldRemoveErrorBlocks,
      );
      this.props.onSubmit(content, this.state.topicId);
      this.props.onSaveDraft!(null as any);
      this.setState({ topicId: this.props.topicId!, post: null as any });
    }
  };

  handleTopicChange = (topicId: string) => this.setState({ topicId });
  setEditorEvents = (
    editorEvents: IEditorEventsContext,
    events: IEditorEvents,
  ) => {
    this.editorEvents = editorEvents;
    this.editorEventTypes = events;
  };

  render() {
    const { isOpen, mobile, forceBlackAndWhite } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        withCloseButton={!mobile}
        skin={ModalSkin.NEW_POST}
      >
        <div
          className={st(
            classes.root,
            { mobile, bw: forceBlackAndWhite } as any,
            this.props.className,
          )}
          data-hook={NEW_POST_MODAL}
        >
          {mobile ? this.renderMobileLayout() : this.renderDesktopLayout()}
        </div>
      </Modal>
    );
  }

  isPostButtonDisabled = () => {
    return !this.state.post || this.isLoading();
  };

  renderDesktopLayout() {
    const { topicId } = this.state;
    const {
      t,
      isPostPublishing,
      feed,
      feedItemId,
      forceBlackAndWhite,
    } = this.props;
    const isTopicEditable = !feed.feedFilters[EFilterKeys.TOPICS];

    return (
      <>
        <Author forceBlackAndWhite={!!forceBlackAndWhite} />
        {this.renderPostEditor()}
        <div className={classes.actionButtons}>
          <FeedTopics
            feedItemId={feedItemId}
            isEditable={isTopicEditable}
            topicId={topicId}
            onTopicChange={this.handleTopicChange}
          />
          <div>
            <Button
              className={classes.cancelButton}
              onClick={this.handleCancel}
            >
              {t('groups-web.discussion.new-post.cancel')}
            </Button>
            <Button
              forceBlackAndWhite={forceBlackAndWhite}
              priority={ButtonPriority.primary}
              disabled={this.isPostButtonDisabled()}
              onClick={this.handleSubmit}
              prefixIcon={this.isLoading() ? <Loader /> : undefined}
            >
              {isPostPublishing
                ? t('groups-web.discussion.new-post.publishing')
                : t('groups-web.discussion.new-post.publish')}
            </Button>
          </div>
        </div>
      </>
    );
  }

  renderMobileLayout() {
    const { t, forceBlackAndWhite } = this.props;
    return (
      <>
        <div className={classes.mobileHeader}>
          <TextButton
            forceBlackAndWhite={forceBlackAndWhite}
            onClick={this.handleClose}
            priority={TextButtonPriority.secondary}
          >
            {t('groups-web.discussion.new-post.mobile.back')}
          </TextButton>
          <Text
            typography={TextTypography.smallTitle}
            className={classes.mobileTitle}
          >
            {t('groups-web.discussion.new-post.mobile.title')}
          </Text>
          <TextButton
            forceBlackAndWhite={forceBlackAndWhite}
            onClick={this.handleSubmit}
            priority={TextButtonPriority.primary}
            disabled={this.isPostButtonDisabled()}
          >
            {t('groups-web.discussion.new-post.mobile.post')}
          </TextButton>
        </div>
        {this.renderPostEditor()}
      </>
    );
  }

  private isLoading() {
    const { isPostPublishing } = this.props;
    const { isBusy } = this.state;

    return isPostPublishing || isBusy;
  }

  private readonly handleBusyChange = (isBusy: boolean) => {
    this.setState({ isBusy });
  };

  private renderPostEditor() {
    const {
      mobile,
      initialContentState,
      t,
      forceBlackAndWhite,
      feedItemId,
    } = this.props;
    return (
      <div data-hook="new-post-modal">
        <React.Suspense
          fallback={<Spinner forceBlackAndWhite={forceBlackAndWhite} />}
        >
          <RichContentEditor
            parentClass={classes.root}
            contentId={feedItemId}
            forceBlackAndWhite={forceBlackAndWhite}
            ref={this.setEditorRef}
            autoFocus
            preset={PRESETS.POST_EDITOR}
            isMobile={mobile}
            content={initialContentState}
            onChange={this.setPost}
            initEditorEvents={this.setEditorEvents}
            onBusyChange={this.handleBusyChange}
            usage="NewPostModal"
            placeholder={t('groups-web.discussion.new-post.placeholder')}
          />
        </React.Suspense>
      </div>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withExperiments,
  withTPAConfig,
  WithGroup,
  withErrorMonitor,
  withTheme,
);

export const NewPostModal = enhance(
  NewPostModalComponent,
) as React.ComponentType<NewPostModalProps>;

import React, { useEffect, useState } from 'react';
import { Avatar, Text, TextTypography, ButtonPriority } from 'wix-ui-tpa';

import { PluginsIconBar } from './PluginsIconBar';

// STYLES
import { classes, st } from './NewPost.st.css';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';

import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../contexts/TPAComponent/withTpaComponentsConfig';
import { compose } from '../../../../../common/utils/compose';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../Settings/settingsParams';
import { ContentConverter } from '../../../../../common/ContentEditor/content/ContentConverter';
import { NEW_POST_BOX } from '../dataHooks';
import {
  getNewPostUiVersion,
  improvedNewPostUiVersionEnabled,
  pluginsAtFooterEnabled,
  pluginsAtRightEnabled,
} from 'Group/Widget/Discussion/NewPost/experiments';
import { Button } from 'common/components/Button';
import { InitialIntentToolbar } from 'Group/Widget/Discussion/NewPost/InitialIntentToolbar/InitialIntentToolbar';

export interface NewPostBoxProps {
  draft?: RawDraftContentState<any>;

  onClick(): void;
  updatingCurrentMember?: boolean;
  profilePhoto: string;
}

type Props = NewPostBoxProps & WithSiteMembers & WithTpaComponentsConfigProps;
const NewPostComponent: React.FC<Props> = (props) => {
  const { onClick, mobile, draft, updatingCurrentMember, profilePhoto } = props;
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const rootRef = React.useRef<HTMLDivElement>(null);
  const placeholder = settings.get(settingsParams.newPostBoxLabel);
  const uiVersion = getNewPostUiVersion(experiments);
  const placeholderValue = draft
    ? ContentConverter.parseTextFromRaw(draft)
    : placeholder || t('groups-web.discussion.create-post.placeholder');
  const [shouldStick, setShouldStick] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

    function handleScroll() {
      if (!rootRef.current) {
        return;
      }
      const { top } = rootRef.current.getBoundingClientRect();
      setShouldStick(top < 0);
    }
  }, []);

  return (
    <div
      onClick={(!updatingCurrentMember || undefined) && onClick}
      onKeyPress={(!updatingCurrentMember || undefined) && onClick}
      data-hook={NEW_POST_BOX}
      className={st(classes.root, {
        mobile,
        hasDraft: !!draft,
        updatingCurrentMember,
        uiVersion,
        sticky: shouldStick,
      } as any)}
      aria-label={t('groups-web.discussion.create-post.placeholder')}
      tabIndex={0}
      role="button"
      ref={rootRef}
    >
      <div className={classes.createPostRow}>
        <Avatar src={profilePhoto} className={classes.avatar} />
        <Text
          typography={TextTypography.runningText}
          className={classes.placeholder}
        >
          {placeholderValue}
        </Text>

        {pluginsAtRightEnabled(uiVersion) && !mobile && (
          <InitialIntentToolbar onIconClick={onClick} />
        )}

        {improvedNewPostUiVersionEnabled(uiVersion) ? (
          <Button className={classes.mainCTA} priority={ButtonPriority.primary}>
            {t('groups-web.discussion.new-post.cta')}
          </Button>
        ) : (
          !draft && <PluginsIconBar mobile={mobile} />
        )}
      </div>

      {pluginsAtFooterEnabled(uiVersion) && !mobile && (
        <InitialIntentToolbar blockMode onIconClick={onClick} />
      )}
    </div>
  );
};

NewPostComponent.defaultProps = {
  // TODO: currentSiteMember: Anonymous as Member,
};

const enhance = compose(withTpaComponentsConfig, withSiteMembers);

export const NewPost = enhance(
  NewPostComponent,
) as React.ComponentType<NewPostBoxProps>;

NewPost.displayName = 'NewPost';

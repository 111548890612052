import React from 'react';
import { Text } from 'wix-ui-tpa';
import { ApiTypes } from '@wix/social-groups-api';

import { st, classes } from './QA.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { compose } from '../../../../../../common/utils/compose';
import { withTheme, Theme } from '../../../../../../common/context/theme';

interface QuestionAnswerProps {
  index: number;
  qa: ApiTypes.v1.QuestionAnswer;
}

type Props = QuestionAnswerProps & WithTranslation & Theme;

const QuestionAnswerComponent: React.FC<Props> = ({
  qa: { question, textAnswer },
  index,
  t,
  forceBlackAndWhite,
}) => {
  return (
    <div className={st(classes.root, { bw: !!forceBlackAndWhite })}>
      <Text className={classes.question}>
        {index}. {question!.textQuestion}? {question!.required ? '*' : ''}
      </Text>
      <Text className={classes.answer}>
        {textAnswer || t('groups-web.membership-questions.no-response')}
      </Text>
    </div>
  );
};

const enhance = compose(withTranslation(), withTheme);

export const QuestionAnswer = enhance(
  QuestionAnswerComponent,
) as React.ComponentType<QuestionAnswerProps>;

import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';

import { Modal } from '../../../../../common/components/Modal/Modal';
import { Button } from '../../../../../common/components/Button/Button';
import { Theme, withTheme } from '../../../../../common/context/theme';
import { compose } from '../../../../../common/utils/compose';

interface CanNotAddMembersModalProps {
  isOpen: boolean;
  onClose(): void;
}

const CanNotAddMembersModalComponent: React.FC<
  CanNotAddMembersModalProps & WithTranslation & Theme
> = ({ isOpen, onClose, t, forceBlackAndWhite }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header
        title={t('groups-web.discussion.can-not-add-members-popup.title')}
        subtitle={t('groups-web.discussion.can-not-add-members-popup.subtitle')}
      />
      <Modal.Buttons>
        <Button
          forceBlackAndWhite={forceBlackAndWhite}
          priority={ButtonPriority.primary}
          onClick={onClose}
        >
          {t('groups-web.discussion.can-not-add-members-popup.back-to-group')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

const enhance = compose(withTranslation(), withTheme);

export const CanNotAddMembersModal = enhance(
  CanNotAddMembersModalComponent,
) as React.ComponentType<CanNotAddMembersModalProps>;

import React from 'react';
import { TextButtonPriority } from 'wix-ui-tpa';

import { ApiTypes, canEdit, GroupDTO } from '@wix/social-groups-api';

// STYLES
import { classes, st } from './About.st.css';
import { AboutEditor } from './AboutEditor';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import {
  GroupUpdate,
  withGroupUpdate,
} from '../../contexts/GroupActions/GroupUpdate';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../contexts/TPAComponent/withTpaComponentsConfig';
import { MemberInvites } from '../../../../common/context/member-invites/MemberInvites';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import { TextButton } from '../../../../common/components/TextButton/TextButton';
import {
  Block,
  BlockAlign,
  BlockFlow,
} from '../../../../common/components/Block';
import { Box } from '../../../../common/components/Box/Box';
import { ContentConverter } from '../../../../common/ContentEditor/content/ContentConverter';
import { RichContentViewer } from '../RichContent/Viewer';
import { PRESETS } from '../RichContent/types';
import { RawDraftContentState } from '../../../../common/ContentEditor/types';
import { compose } from '../../../../common/utils/compose';
import { withMemberInvites } from '../../../../common/context/member-invites/withMemberInvites';
import { Header } from '../../../../common/components/Text/Header';
import {
  groupsAboutEditClicked,
  groupsAboutEditSave,
} from '@wix/bi-logger-groups/v2';

export const ABOUT_GROUP = 'about-group';

interface AboutProps {
  group: ApiTypes.v1.GroupResponse;
  updateProgress: UpdateProgress;
}

type Props = AboutProps &
  WithTranslation &
  GroupUpdate &
  WithTpaComponentsConfigProps &
  InjectedBiLoggerProps &
  MemberInvites;

interface AboutComponentState {
  editing: boolean;
}

class AboutComponent extends React.Component<Props, AboutComponentState> {
  static displayName = 'AboutComponent';
  state: AboutComponentState = {
    editing: false,
  };
  editMode = () => {
    const { bi, group } = this.props;
    this.setState({ editing: true });
    bi.report(
      groupsAboutEditClicked({
        groupId: group.groupId!,
        origin: 'edit_descr_link',
        userEntry: BIUserEntry.SITE,
      }),
    );
    this.props.setEditMode(UpdateProgress.STARTED);
  };

  viewMode = () => {
    this.setState({ editing: false });
    this.props.setEditMode(UpdateProgress.STALE);
  };

  componentDidCatch(error: any, info: any) {
    console.error(error, info);
  }

  render() {
    const { t, group, mobile } = this.props;
    const titleLabel = t('groups-web.about');

    const editing = this.editing();
    const editButton =
      canEdit(group) && !editing ? (
        <TextButton
          priority={TextButtonPriority.primary}
          onClick={this.editMode}
        >
          {t('groups-web.viewer.about.edit')}
        </TextButton>
      ) : null;
    return (
      <Block align={BlockAlign.start} flow={BlockFlow.row}>
        <Box
          withTopBottomBorders={!mobile}
          withSideBorders={!mobile}
          className={st(classes.aboutRoot, { mobile } as any)}
        >
          <div className={classes.headerWrapper}>
            <Header className={classes.header} data-hook={ABOUT_GROUP}>
              {titleLabel}
            </Header>
            {editButton}
          </div>
          {editing ? this.renderEditor() : this.renderViewer()}
        </Box>
      </Block>
    );
  }

  private editing() {
    return this.state.editing;
  }

  private readonly updateAbout = (contentString: string) => {
    const { bi, group } = this.props;
    const g = new GroupDTO(this.props.group);
    const text = ContentConverter.parseText(contentString);
    bi.report(
      groupsAboutEditSave({
        groupId: group.groupId!,
        length: text.length,
        origin: 'modal_scrn_save_btn',
        userEntry: BIUserEntry.SITE,
      }),
    );
    const { details } = g.setDescription(contentString);
    // TODO: FIX IT!!!!!1111oneoneone
    this.props.updateGroup(g.getUpdatePaths(), details!);
    // TODO: fix flaky UX (optimistic update)
    this.viewMode();
  };

  private renderViewer(): React.ReactNode {
    const contentState = this.getRawContentState();
    return (
      <div className={classes.viewer}>
        <RichContentViewer
          disablePreview
          content={contentState}
          preset={PRESETS.ABOUT}
          usage="About"
          contentId="about_group"
        />
      </div>
    );
  }

  private renderEditor() {
    const { mobile } = this.props;

    return (
      <AboutEditor
        mobile={mobile!}
        content={this.getRawContentState()}
        saveChanges={this.updateAbout}
        cancel={this.viewMode}
        loading={this.props.updateProgress === UpdateProgress.UPDATING}
      />
    );
  }

  private getRawContentState(): RawDraftContentState<any> {
    const g = new GroupDTO(this.props.group);
    const description = g.getDescription();
    return ContentConverter.parseContentString(description);
  }
}

const enhance = compose(
  withGroupUpdate,
  withTranslation(),
  withTpaComponentsConfig,
  withMemberInvites,
  withBi,
);

export const About = enhance(AboutComponent) as React.ComponentType<AboutProps>;
About.displayName = 'About';

import type { Experiments } from '@wix/yoshi-flow-editor';

const enum NewPostUiVersion {
  Current = 'A',
  PluginsAtFooter = 'B',
  PluginsAtRight = 'C',
}

export function getNewPostUiVersion(
  experiments: Experiments,
): NewPostUiVersion {
  return experiments.get('specs.groups.ImprovedCreatePost');
}

export function improvedNewPostUiVersionEnabled(version: NewPostUiVersion) {
  return version !== NewPostUiVersion.Current;
}

export function pluginsAtFooterEnabled(version: NewPostUiVersion) {
  return version === NewPostUiVersion.PluginsAtFooter;
}

export function pluginsAtRightEnabled(version: NewPostUiVersion) {
  return version === NewPostUiVersion.PluginsAtRight;
}

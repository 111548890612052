import React from 'react';

import {
  ApiTypes,
  isGroupSecret,
  isPendingGroup,
  RequestState,
} from '@wix/social-groups-api';

import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withBi,
  withExperiments,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import { InviteMembersProps } from '../../../types/MembershipAction';
import {
  withAppData,
  WithAppDataProps,
} from '../../../contexts/AppData/withAppData';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../contexts/TPAComponent/withTpaComponentsConfig';
import { getMembersLabel } from '../../MembersLabel/MembersLabel';
import { CanNotAddMembersModal } from '../CanNotAddMemebersModal/CanNotAddMembersModal';
import { ModalV2 } from '../../../../../common/components/Modal/ModalV2';
import { BIUserEntry } from '../../../../../common/bi-logger/types';
import { compose } from '../../../../../common/utils/compose';
import { withInviteMembers } from '../../../contexts/InviteMembers/WithInviteMembers';
import {
  groupsAddMemberClicked,
  groupsSelectAllMembersClicked,
  inviteSent,
} from '@wix/bi-logger-groups/v2';
import { AddMembersBar } from './AddMembersBar';
import { SearchBar } from './SearchBar';
import { AddMembers } from './AddMembers';
import { IMembersRequestState } from '../../../controllers/members/MembersControllerProps';
import { Theme, withTheme } from '../../../../../common/context/theme';
import { classes, st } from './AddMembersModal.st.css';

export interface AddMembersModalProps {
  isOpen: boolean;
  group: ApiTypes.v1.GroupResponse;
  handleClose(): void;
}

type AddMembersProps = AddMembersModalProps &
  WithTranslation &
  WithSiteMembers &
  InviteMembersProps &
  WithAppDataProps &
  InjectedBiLoggerProps &
  WithTpaComponentsConfigProps &
  Theme &
  InjectedExperimentsProps;

interface AddMembersModalState {
  searchQuery: string;
  invitesMap: { [email: string]: number | null };
  selectAll: boolean;
  excludedIds: Set<string>;
  selectedIds: Set<string>;
}

export const ADD_MEMBERS_HOOK = 'add-members';

class AddMembersModalComponent extends React.Component<
  AddMembersProps,
  AddMembersModalState
> {
  static defaultProps = {
    siteMembers: [],
  };

  state: AddMembersModalState = {
    searchQuery: '',
    invitesMap: {},
    selectAll: false,
    excludedIds: new Set<string>(),
    selectedIds: new Set<string>(),
  };

  componentDidUpdate(prevProps: Readonly<AddMembersProps>) {
    // after closed
    if (prevProps.isOpen && !this.props.isOpen) {
      this.resetSelectedState();
    }
    // after opened
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.setNonGroupMembers();
    }

    if (this.isAddingMembersFinished(prevProps.membersRequest)) {
      this.resetSelectedState();
    }
  }

  private isAddingMembersFinished(requestState: IMembersRequestState) {
    return (
      (this.state.selectAll || this.state.selectedIds.size) &&
      requestState &&
      requestState.queryNonGroupMembers === RequestState.SUCCESS
    );
  }

  private resetSelectedState() {
    this.setState({
      searchQuery: '',
      selectAll: false,
      excludedIds: new Set(),
      selectedIds: new Set(),
    });
  }

  setSearchQuery = (value: string) => {
    this.setState({ searchQuery: value });
  };

  render() {
    const {
      t,
      isOpen,
      handleClose,
      group,
      nonGroupMembersCount,
      membersRequest,
      forceBlackAndWhite,
    } = this.props;
    const {
      searchQuery,
      selectAll,
      invitesMap,
      selectedIds,
      excludedIds,
    } = this.state;
    const { key, value } = getMembersLabel(group, t);
    const title = t(`${key}.add-widget.title`, { membersLabel: value });
    const nonGroupMembers = this.getNonGroupMembers();
    const hasNonGroupMembers = !!nonGroupMembers.length;

    const isSecret = isGroupSecret(group);
    const canShowSearch = isSecret ? hasNonGroupMembers || searchQuery : true;

    if (isPendingGroup(group)) {
      return <CanNotAddMembersModal isOpen={isOpen} onClose={handleClose} />;
    }

    return (
      <div className={st(classes.root, { bw: !!forceBlackAndWhite })}>
        <ModalV2 isOpen={isOpen} onRequestClose={handleClose}>
          <ModalV2.Title>{title}</ModalV2.Title>
          {canShowSearch ? (
            <SearchBar
              count={nonGroupMembersCount}
              isSecret={isSecret}
              onChange={this.setSearchQuery}
              withCloseButton={!!searchQuery}
            />
          ) : null}
          <ModalV2.Content>
            <AddMembers
              members={nonGroupMembers}
              onSelect={this.handleMemberSelect}
              done={!!invitesMap[searchQuery.trim()]}
              membersRequest={membersRequest}
              invite={this.inviteMemberByEmail}
              isSecret={isSecret}
              searchQuery={searchQuery}
              selectedIds={selectedIds}
              excludedIds={excludedIds}
              selectAll={selectAll}
            />
          </ModalV2.Content>
          <AddMembersBar
            totalCount={nonGroupMembers.length}
            selectedCount={this.getSelectedMembersCount()}
            onAdd={this.addMembers}
            onSelectAll={this.handleSelectAllChanges}
            showCheckbox={!searchQuery}
            selectAll={selectAll}
            updating={this.areMembersUpdating()}
          />
        </ModalV2>
      </div>
    );
  }

  handleMemberSelect = (memberId: string, selected: boolean) => {
    const { selectedIds, excludedIds, selectAll } = this.state;
    const { nonGroupMembersCount } = this.props;

    const newSelectedIds = new Set(selectedIds);
    const newExcludedIds = new Set(excludedIds);
    if (selected) {
      newSelectedIds.add(memberId);
      newExcludedIds.delete(memberId);
    } else {
      newSelectedIds.delete(memberId);
      newExcludedIds.add(memberId);
    }

    const allExcluded = nonGroupMembersCount === newExcludedIds.size;

    this.setState({
      selectedIds: newSelectedIds as any,
      excludedIds: newExcludedIds as any,
      selectAll: allExcluded ? false : selectAll,
    });
  };

  addMembers = () => {
    const { bi, group, addAllNonGroupMembers, addMembers } = this.props;
    const { selectAll, selectedIds, excludedIds } = this.state;

    bi.report(
      groupsAddMemberClicked({
        origin: 'modal_plus_add_btn',
        groupId: group.groupId!,
        userEntry: BIUserEntry.SITE,
      }),
    );

    if (selectAll !== null) {
      bi.report(
        groupsSelectAllMembersClicked({
          origin: 'members_list_scr',
          groupId: group.groupId!,
          userEntry: BIUserEntry.SITE,
          action: selectAll ? 'select' : 'unselect',
        }),
      );
    }

    if (selectAll) {
      addAllNonGroupMembers(group, Array.from(excludedIds));
    } else {
      addMembers(group, Array.from(selectedIds));
    }
  };

  inviteMemberByEmail = () => {
    const { inviteMembersByEmail, bi } = this.props;
    const { searchQuery } = this.state;
    bi.report(
      inviteSent({
        group_id: this.props.group.groupId,
        site_member_id: null,
        contact: searchQuery,
        origin: 'dialog_scr_invite_btn',
      } as any),
    );
    this.addEmailToInvitesSent(searchQuery.trim());
    inviteMembersByEmail([searchQuery.trim()]);
  };

  private addEmailToInvitesSent(email: string) {
    const { invitesMap } = this.state;
    this.setState({ invitesMap: { ...invitesMap, [email]: 42 } });
  }

  private readonly handleSelectAllChanges = () => {
    this.setState({
      selectAll: !this.state.selectAll,
      excludedIds: new Set(),
      selectedIds: new Set(),
    });
  };

  private readonly getSelectedMembersCount = (): number => {
    const { nonGroupMembersCount } = this.props;
    const { selectAll, excludedIds, selectedIds } = this.state;

    if (selectAll) {
      return nonGroupMembersCount - excludedIds.size;
    }
    return selectedIds.size;
  };

  private areMembersUpdating() {
    const { membersUpdate } = this.props;
    return membersUpdate && !!membersUpdate.length;
  }

  private getNonGroupMembers() {
    const { siteMembers } = this.props;
    const { searchQuery } = this.state;
    return siteMembers.filter((m) => {
      return (
        m.name &&
        m.name.nick &&
        m.name.nick
          .toLocaleLowerCase()
          .includes(searchQuery.trim().toLocaleLowerCase())
      );
    });
  }
}

const enhance = compose(
  withTranslation(),
  withSiteMembers,
  withBi,
  withAppData,
  withInviteMembers,
  withTpaComponentsConfig,
  withExperiments,
  withTheme,
);

export const AddMembersModal = enhance(
  AddMembersModalComponent,
) as React.ComponentType<AddMembersModalProps>;

AddMembersModal.displayName = 'AddMembersModal';

import { ApiTypes, GroupWrapper } from '@wix/social-groups-api';

export enum MembersLabelKeys {
  Members = 'Members',
  Students = 'Students',
  Coworkers = 'Coworkers',
  Friends = 'Friends',
  Gamers = 'Gamers',
  Custom = 'Custom',
}

export type MembersLabelTranslations = {
  [key in keyof typeof MembersLabelKeys]: string;
};

export function getMembersLabelKey(
  group: ApiTypes.v1.GroupResponse,
  translationMap: MembersLabelTranslations,
) {
  const groupWrapper = new GroupWrapper(group);
  const membersLabel = groupWrapper.getMembersName();
  const translation = (translationMap as any)[membersLabel];
  const translationKey = membersLabel
    ? translation || translationMap.Custom
    : translationMap.Members;
  return translationKey;
}
